var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      !_vm.loading
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-4",
                      attrs: { title: this.logger.serial },
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-5", attrs: { "align-v": "end" } },
                        [
                          _c("b-col", { attrs: { cols: "6" } }, [
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                " Last seen: " +
                                  _vm._s(_vm.$d(_vm.logger.last_seen, "long")) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("b-col"),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "5",
                                md: "3",
                                xl: "2",
                                "align-self": "end",
                              },
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    right: "",
                                    text: "Admin Actions",
                                    variant: "success",
                                  },
                                },
                                [
                                  _c(
                                    "b-dropdown-item-btn",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName:
                                            "v-b-modal.logger-rssh-start",
                                          modifiers: {
                                            "logger-rssh-start": true,
                                          },
                                        },
                                      ],
                                    },
                                    [_vm._v(" Open Reverse SSH Connection ")]
                                  ),
                                  _c(
                                    "b-dropdown-item-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.rebootCommand()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reboot Comms ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "6" } }, [
                            _vm._v(" File count: " + _vm._s(_vm.fileCount)),
                            _c("br"),
                            _vm._v(
                              " Total Quota: " +
                                _vm._s(
                                  _vm.logger.quota.total
                                    ? _vm.formatFileSize(_vm.logger.quota.total)
                                    : "Unlimited"
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " Used Quota: " +
                                _vm._s(
                                  _vm.formatFileSize(_vm.logger.quota.used)
                                )
                            ),
                            _c("br"),
                          ]),
                          _c(
                            "b-col",
                            { attrs: { md: "6" } },
                            [
                              _c(
                                "b-table-simple",
                                [
                                  _c(
                                    "b-tbody",
                                    [
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-td", [_vm._v("Status")]),
                                          _vm.logger.status
                                            ? _c("b-td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatStatus(
                                                      _vm.logger.status
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _c("b-td", [
                                                _vm._v("unavailable"),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-td", [
                                            _vm._v("Battery Voltage"),
                                          ]),
                                          _vm.logger.status
                                            ? _c("b-td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.logger.batteryVolts
                                                  ) + " V"
                                                ),
                                              ])
                                            : _c("b-td", [
                                                _vm._v("unavailable"),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-td", [
                                            _vm._v("Ambient Temperature"),
                                          ]),
                                          _vm.logger.status
                                            ? _c("b-td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.logger.ambientTemp
                                                  ) + " °C"
                                                ),
                                              ])
                                            : _c("b-td", [
                                                _vm._v("unavailable"),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-td", [_vm._v("Firmware")]),
                                          _vm.logger.status
                                            ? _c("b-td", [
                                                _vm._v(
                                                  _vm._s(_vm.logger.firmware)
                                                ),
                                              ])
                                            : _c("b-td", [
                                                _vm._v("unavailable"),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-td", [_vm._v("Free Memory")]),
                                          _vm.logger.status
                                            ? _c("b-td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.logger.freeMemory
                                                  ) + " %"
                                                ),
                                              ])
                                            : _c("b-td", [
                                                _vm._v("unavailable"),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-3", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "secondary",
                                    to: "/logger/" + this.selected_logger,
                                    block: "",
                                  },
                                },
                                [
                                  _vm._v(" Go to logger page "),
                                  _c("fa-icon", {
                                    attrs: { icon: "arrow-right" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("logger-page-users", {
                                attrs: {
                                  lid: this.selected_logger,
                                  title: "Logger Ownership",
                                  admin: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("p", { staticClass: "lead" }, [
                                _vm._v("Agent Status"),
                              ]),
                              _c("b-table", {
                                attrs: {
                                  outlined: "",
                                  items: _vm.agentStatusItems,
                                  "empty-text": "No agent status available",
                                  "show-empty": "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "mt-4 mb-4",
                                  attrs: {
                                    header: "Danger Area",
                                    "border-variant": "danger",
                                    "header-border-variant": "danger",
                                    "header-text-variant": "danger",
                                    align: "center",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { "align-h": "center" },
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            order: "1",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Remove current login information from database. This will require the logger to be re-authenticated in PMScreen. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            order: "2",
                                            "order-md": "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.oem-logger-deauth",
                                                  modifiers: {
                                                    "oem-logger-deauth": true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                variant: "outline-danger",
                                                block: "",
                                              },
                                            },
                                            [_vm._v(" Deauthenticate Logger ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            order: "3",
                                            "order-md": "2",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Delete all files that are stored in pmgateway.online from the logger. "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            order: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.oem-logger-purge",
                                                  modifiers: {
                                                    "oem-logger-purge": true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                variant: "outline-danger",
                                                block: "",
                                              },
                                            },
                                            [_vm._v(" Delete All Files ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass: "mt-5",
                                      attrs: { "align-h": "center" },
                                    },
                                    [
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _vm._v(
                                          " Delete any data associated with this logger from the system. "
                                        ),
                                      ]),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.oem-logger-delete",
                                                  modifiers: {
                                                    "oem-logger-delete": true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                variant: "danger",
                                                block: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Delete Logger From System "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "oem-logger-delete",
            size: "lg",
            title: "Delete Logger",
          },
          on: {
            show: _vm.checkDeletePrerequisites,
            hidden: _vm.deleteModalClosed,
          },
        },
        [
          _c("p", [
            _vm._v(
              " Are you sure you want to delete this logger? This action is irreversible! "
            ),
          ]),
          _c("p", [
            _vm._v(" Prerequisites: "),
            _c("ul", [
              _c(
                "li",
                {
                  class: _vm.deletion.hasNoFiles
                    ? "text-success"
                    : "text-danger",
                },
                [
                  _vm._v(" Logger has no files stored on system "),
                  _vm.deletion.hasNoFiles == true
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "check" },
                      })
                    : _vm.deletion.hasNoFiles === false
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "times" },
                      })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "primary" },
                      }),
                ],
                1
              ),
              _c(
                "li",
                {
                  class: _vm.deletion.hasBeenDeauthed
                    ? "text-success"
                    : "text-danger",
                },
                [
                  _vm._v(" Logger has been deauthenticated "),
                  _vm.deletion.hasBeenDeauthed == true
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "check" },
                      })
                    : _vm.deletion.hasBeenDeauthed === false
                    ? _c("fa-icon", {
                        staticClass: "ml-1",
                        attrs: { icon: "times" },
                      })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "primary" },
                      }),
                ],
                1
              ),
            ]),
          ]),
          !_vm.deletePrerequisitesMet
            ? _c("p", { staticClass: "text-danger" }, [
                _vm._v(" Please resolve the prerequisites before deleting "),
              ])
            : _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(" Confirm the logger serial number below to continue "),
              ]),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Type logger serial number",
                      formatter: _vm.formatSerialNumber,
                      disabled: !_vm.deletePrerequisitesMet,
                    },
                    model: {
                      value: _vm.deletion.serialConfirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.deletion, "serialConfirmation", $$v)
                      },
                      expression: "deletion.serialConfirmation",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "danger",
                            disabled:
                              !_vm.logger ||
                              _vm.deletion.serialConfirmation !==
                                _vm.logger.serial,
                          },
                          on: { click: _vm.deleteLogger },
                        },
                        [_vm._v(" DELETE LOGGER ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "oem-logger-deauth", title: "Deauthenticate Logger" },
          on: {
            hidden: function ($event) {
              _vm.deauth.serialConfirmation = ""
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " If you deauthenticate this logger, it will stop communicating with PMGateway. The only way to resume communications will be for the owner account to login again using PMScreen. "
            ),
          ]),
          _c("p", [
            _vm._v(
              " All files will remain on PMGateway, and users will still be able to access it. "
            ),
          ]),
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v(" Confirm the logger serial number below to continue. "),
          ]),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Type logger serial number",
                      formatter: _vm.formatSerialNumber,
                    },
                    model: {
                      value: _vm.deauth.serialConfirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.deauth, "serialConfirmation", $$v)
                      },
                      expression: "deauth.serialConfirmation",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "danger",
                            disabled:
                              !_vm.logger ||
                              _vm.deauth.serialConfirmation !==
                                _vm.logger.serial,
                          },
                          on: { click: _vm.deauthenticateLogger },
                        },
                        [_vm._v(" DEAUTHENTICATE ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "oem-logger-purge", title: "Delete All Files" },
          on: {
            hidden: function ($event) {
              this.purge.serialConfirmation = ""
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " This will permenantely delete all files stored for this logger on PMGateway. "
            ),
          ]),
          _vm.fileCount > 0
            ? _c("p", [
                _vm._v(
                  " " + _vm._s(_vm.fileCount) + " files will be deleted. "
                ),
              ])
            : _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(" There are no files stored for this logger. "),
              ]),
          _vm.fileCount > 0
            ? _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(" Confirm the logger serial number below to continue. "),
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "Type logger serial number",
                      formatter: _vm.formatSerialNumber,
                      disabled: _vm.fileCount == 0,
                    },
                    model: {
                      value: _vm.purge.serialConfirmation,
                      callback: function ($$v) {
                        _vm.$set(_vm.purge, "serialConfirmation", $$v)
                      },
                      expression: "purge.serialConfirmation",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: {
                            variant: "danger",
                            disabled:
                              !_vm.logger ||
                              _vm.purge.serialConfirmation !==
                                _vm.logger.serial,
                          },
                          on: { click: _vm.purgeFiles },
                        },
                        [_vm._v(" DELETE ALL FILES ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "oem-file-delete-progress",
            "hide-header": "",
            "body-class": "text-center",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            visible: !!_vm.purge.total,
          },
        },
        [
          _c("b-spinner", { attrs: { variant: "primary" } }),
          _c("p", { staticClass: "lead" }, [
            _vm._v(" Deleting " + _vm._s(_vm.purge.total) + " files... "),
          ]),
          _c("b-progress", {
            attrs: {
              max: _vm.purge.total,
              value: _vm.purge.deleted,
              "show-value": "",
              height: "2rem",
            },
          }),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v(" Closing this window will cancel the operation "),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "logger-rssh-start",
            title: "Open Reverse SSH Connection",
            size: "lg",
          },
          on: { ok: _vm.reverseSsh },
        },
        [
          _c("p", { staticClass: "text-muted" }, [
            _vm._v(
              " When creating a reverse SSH connection, the logger will initiate an SSH connection to the provided server with a reverse port forwarding rule in place to open a port on the SSH server and tunnel it back to port 22 on the logger. An SSH session can then be opened on the remote server by pointing an SSH client at the tunnelled port. "
            ),
          ]),
          _c(
            "b-form",
            { attrs: { novalidate: "" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Server Address",
                    description:
                      "SSH Server to open a reverse ssh connection to",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "e.g 192.0.2.1",
                      state: _vm.rsshState.server,
                    },
                    model: {
                      value: _vm.rssh.server,
                      callback: function ($$v) {
                        _vm.$set(_vm.rssh, "server", $$v)
                      },
                      expression: "rssh.server",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Server Port",
                    description: "Port that the SSH server is listening on",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.rsshState.serverPort,
                      type: "number",
                      min: "1",
                      max: "65535",
                    },
                    model: {
                      value: _vm.rssh.serverPort,
                      callback: function ($$v) {
                        _vm.$set(_vm.rssh, "serverPort", $$v)
                      },
                      expression: "rssh.serverPort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Username",
                    description: "Username for logging into the SSH server",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { state: _vm.rsshState.username },
                    model: {
                      value: _vm.rssh.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.rssh, "username", $$v)
                      },
                      expression: "rssh.username",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(" Please enter a username for the SSH server "),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Password",
                    description: "Password for logging into the SSH server",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.rssh.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.rssh, "password", $$v)
                      },
                      expression: "rssh.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Reverse Port",
                    description:
                      "Port to open on the SSH server that will tunnel to the logger",
                  },
                },
                [
                  _c("b-input", {
                    attrs: {
                      type: "number",
                      min: "1",
                      max: "65535",
                      state: _vm.rsshState.port,
                    },
                    model: {
                      value: _vm.rssh.port,
                      callback: function ($$v) {
                        _vm.$set(_vm.rssh, "port", $$v)
                      },
                      expression: "rssh.port",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " Note: The username and password provided will be temporarily stored in the PMGateway server database. It's recommended to use a restricted account to perform Reverse SSH. "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }